
.main-content{
  text-align: left;
  padding: 20px;
  background-color: #F7F7F7;
}

.page-content {
  padding: 20px;
  background-color: #FFF;
}
.page-content > h1 {
  margin: 22px 0;
  font-weight: 400;
  font-size: 22px;
}
.page-content > a {
  text-decoration: none;
}
.logout_button{
  background-color: white;
  color: gray;
  top: 53px;
  position: relative;
}
