.main-content#User div:nth-child(2) > div {
  padding-bottom: 0 !important;
  margin-bottom: 10px !important;
}

.solid {
  padding-left: 0.8571428571428571rem;
  padding-right: 0.8571428571428571rem;
  width: 100%;
}

.solid hr {
  border-top: solid 1px #e8e8e8;
}

.user_button__validate button {
  margin-top: 30px;
}
