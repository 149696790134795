.Modal:last-child .Modal-Content {
  max-width: 1000px;
}

.CustomFieldModalContent .fields-set {
  display: flex;
  align-items: flex-start;
}
.CustomFieldModalContent .fields-set > * {
  flex: 1 1 0;
}
.CustomFieldModalContent .fields-set > *:not(:last-child) {
  margin-right: 16px;
}
.CustomFieldModalContent .fields-set > *:not(.SelectField) label {
  margin-bottom: 8px;
}
.CustomFieldModalContent .fields-set > .checkbox-field {
  padding-top: 12px;
}
.CustomFieldModalContent .fields-set > .checkbox-field > label {
  font-size: 12px;
  margin-bottom: 16px;
  display: block;
}
