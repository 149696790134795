.side-menu-workfloo {
  box-shadow: 1px 1px 1px 1px rgba(240, 240, 240, 0.8);
  height: 100%;
  z-index: 1;
  font-size: 14px;
  background-color: #2a3235;
  box-shadow: none;
  flex: none;
  width: 256px;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 1001;
}

.side-menu-workfloo .menu-item-workfloo {
  color: #fff;
  text-decoration: none;
  padding: 8px 16px;
  overflow: hidden;
}

.side-menu-workfloo .menu-item-active-workfloo {
  background-color: #3e464b;
  color: rgb(100, 171, 255);
  text-decoration: none;
  padding: 8px 16px;
  overflow: hidden;
}

.side-menu-workfloo .menu-item-workfloo:hover {
  background-color: #777d81;
}

.menu-item-content-active-workfloo > a {
}

.option-container {
  cursor: pointer;
}

.logo-menu-container {
  display: flex;
  padding: 20px;
  justify-content: center;
}

.logo-menu-container > img {
  width: 60%;
}
.menu-item-workfloo,
.menu-item-active-workfloo {
  display: flex;
  padding-left: 20px;
  justify-content: space-between;
}

.menu-item-icon {
  width: 16px;
  margin-right: 20px;
}

.menu-item .submenu-workfloo-item {
  font-size: 14px;
  color: #474747;
  line-height: 18px;
  -webkit-font-smoothing: antialiased;
  cursor: pointer;
}
.menu-item-workfloo:hover,
.submenu-workfloo-item:hover {
  background-color: #777d81;
}

.submenu-items {
  display: none;
}

.submenu-icon {
  color: #fff;
  width: 12px;
}
.menu-item-active-workfloo .submenu-icon {
  transform: rotate(180deg);
  color: rgb(100, 171, 255);
}

.submenu-items-active {
  display: flex;
  flex-direction: column;
  align-items: baseline;
}
.submenu-workfloo-item {
  padding: 8px 16px 8px 32px;
  color: #fff;
  width: -webkit-fill-available;
  overflow: hidden;
  text-align: initial;
  text-decoration: none;
}

.submenu-workfloo-item-active {
  padding: 8px 16px 8px 32px;
  color: rgb(100, 171, 255);
  text-decoration: none;
  overflow: hidden;
}
