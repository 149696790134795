.ModalScreen_Variable--Container > div {
  margin-bottom: 0px !important;
}

.help {
  font-size: 11px;
}


.ModalScreen_Variable--Container > div > div{
  margin-top: 0px !important;
}