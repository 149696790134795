.FileList-Container {
  background: #fff;
  width: 100%;
  max-width: 280px;
  padding: 30px 16px;
  height: 100%;
  max-height: 770px;
  overflow: auto;
  z-index: 0;
}

.FileList-Container .files-panel-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  padding-left: 10px;
  padding-right: 10px;
}

.FileList-Container .files-panel-header .files-panel-title {
  font-size: 12px;
  color: #0f0f0f;
  opacity: 0.8;
}

.FileList-Container .files-container .file-container {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 10px;
  transition: background-color 0.2s ease-in-out;
  -moz-transition: background-color 0.2s ease-in-out;
  -webkit-transition: background-color 0.2s ease-in-out;
  flex: none;
}
.FileList-Container .files-container .file-container,
.FileList-Container .files-container .file-container label {
  cursor: pointer;
}
.FileList-Container .files-container ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
.FileList-Container .files-container .SortableList--ItemContainer {
  margin: 10px 0 !important;
}
.FileList-Container .files-container .file-container:hover,
.FileList-Container .files-container .file-container.active {
  background-color: rgba(199, 223, 255, 0.3);
  border-radius: 4px;
}
.FileList-Container .files-container .file-container .file-name-container {
  position: relative;
  margin-right: 13px;
  flex: none;
  width: 100%;
  max-width: 107px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.files-panel
  .files-container
  .file-container
  .file-name-container
  .tooltip-indicator {
  position: absolute;
  left: 0;
  top: 100%;
  border: solid 1px #e23e32;
  width: 100%;
  padding: 1px;
  margin-top: -1px;
  color: #ffffff;
  background: #df574d;
  z-index: 100;
}
.FileList-Container .files-container .file-container .file-name {
  display: block;
  width: 100%;
  border: none;
  background: none;
  padding: 1px;
  outline: none;
}

.FileList-Container .files-container .file-container .file-name.error {
  border: solid 1px #e23e32;
}

.FileList-Container .files-container .file-container > svg {
  flex: none;
  width: 18px;
  height: auto;
  stroke: #606367;
  margin-right: 13px;
}
.FileList-Container .files-container .file-container > div:last-child {
  flex: none;
  width: auto;
}