.error403__body {
  align-items: center;
  display: block;
  text-align: center;
}

.error403__header {
  color: #0C111D;
  font-size: 28px;
  font-style: normal;
  font-weight: bold;
  letter-spacing: -0.25px;
  line-height: 24px;
  padding: 45px 0 155px 48px;
}

.error403__img {
  padding-bottom: 36px;
}

.error403__title {
  color: #0C111D;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 33px;
  padding-bottom: 9px;
  text-align: center;
}

.error403__description {
  color: #808692;
  display: inline-block;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  padding-bottom: 36px;
  text-align: center;
  width: 494px;
}
