
.Panel_Main_Container {
    padding: 12px;
    background: #ffffff;
    border-radius: 4px;
    .Panel_Header {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        &:not(:last-child) {
            padding-bottom: 12px; } }
    .Panel_Title {
        font-size: 12px;
        color: #1a2035;
        font-weight: 700;
        margin: 0;
        flex: 1 1 0; }
    .Panel_Section_Container {
        border: solid 1px #e8e8e8;
        border-radius: 4px;
        &.subdued {
            background-color: #f2f2f2; }
        &:not(:last-child) {
            margin-bottom: 16px; }
        .Panel_Section_Header {
            padding: 8px;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            & >  .Panel_Title {
                flex: 1 1 0; } } }
    .Panel_Actions_Container {
        display: flex;
        align-items: center;
        flex: none;
        width: auto;
        & > *:not(:last-child) {
            margin-right: 4px; } } }
