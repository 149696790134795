.form-section {
  padding: 24px;
  border: solid 1px #e7eaee;
  border-radius: 8px;
  margin-top: 40px;
  position: relative;
  min-height: 178px;
  display: flex;
  align-items: stretch;
  flex-wrap: wrap;
}
.form-section .section-name {
  position: absolute;
  top: 0;
  left: 15px;
  color: #808692;
  line-height: 1;
  padding: 8px;
  font-size: 12px;
  transform: translateY(-50%);
  background-color: white;
}
.form-section .FormField {
  padding: 8px;
  flex: none;
  width: 33.33333333%;
  cursor: grab;
  user-select: none;
}
.form-section .FormField.draggable-source--is-dragging {
  opacity: 0.5;
}
.form-section .FormField.draggable-mirror {
  z-index: 9999;
}
.form-section .FormField.draggable-mirror:hover {
  cursor: grabbing;
}
.form-section .FormField .FormField__content {
  border: solid 1px #e7eaee;
  border-radius: 8px;
  padding: 12px;
  background-color: white;
  height: 100%;
}

.form-section .FormField .FormField__name {
  margin-top: 0;
}
.form-section .FormField .FormField__name,
.form-section .FormField .FormField__id {
  color: #565f6c;
  font-size: 12px;
  font-weight: 400;
  word-wrap: break-word;
}
.form-section .FormField .FormField__id strong {
  color: #181f2e;
}
.form-section .FormField button {
  background-color: #f5f6f7;
  color: #323a46;
  border: none;
  font-weight: 600;
  margin-top: 2px;
  position: relative;
  z-index: 1;
}
.form-section .FormField .FormField__content {
  display: flex;
}
.form-section .FormField .FormField__content .FormField__info {
  padding-right: 32px;
  flex: none;
  width: 50%;
  position: relative;
  z-index: 0;
}
.form-section .FormField .FormField__content .FormField__drag-handler {
  position: absolute;
  top: -12px;
  left: 0;
  right: -12px;
  bottom: -12px;
  z-index: 0;
  /* background: rgba(255, 0, 0, 0.5); */
}
.form-section
  .FormField
  .FormField__content
  .FormField__info
  .FormField__drag-handler {
  right: 0;
  left: -12px;
}

.form-section .FormField .FormField__content .FormField__Field {
  position: relative;
  flex: none;
  width: 50%;
  display: flex;
  align-items: center;
  z-index: 0;
}
.form-section
  .FormField
  .FormField__content
  .FormField__Field
  .FormField__Field-Container {
  flex: none;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}
.form-section
  .FormField
  .FormField__content
  .FormField__Field
  .FormField__Field-Container
  *:not(.RadioButton-Input-Container) {
  flex: none !important;
  width: 100% !important;
}

.form-section
  .FormField
  .FormField__content
  .FormField__Field
  .FormField__Field-Container
  .RadioButton-Body,
.form-section
  .FormField
  .FormField__content
  .FormField__Field
  .FormField__Field-Container
  .RadioButton-Container {
  width: auto !important;
  margin-bottom: 4px;
  margin-top: 4px;
}

.form-section
  .FormField
  .FormField__content
  .FormField__Field
  .FormField__Field-Container
  .RadioButton-Container:not(:last-child) {
  margin-right: 14px;
}
