.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height:100vh;  
}

.login-side {
  background-image: url(./../../assets/background-login.jpeg);
 /* Full height */
  height: 100%; 
  width: 70%;
 /* Center and scale the image nicely */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.login-form {
  display: flex;
  flex-direction: column;
  flex: 1 1;
  padding: 40px 60px 0;
  height: 100%;
}

.login-button {
  margin: 15px 0px;
  background-color: #386AF5;
  color: #FFF;
  border-radius: 15px;
  width: 100%;
  padding: 7px;
  border: none;
  cursor: pointer;
}

.login-logo {
  margin-bottom: 80px;
  width: 30%;
}

.login-input, .login-input-error{
  margin: 15px 0px 0;
  background-color: #E6E6E6;
  color: black;
  font-size: 15px;
  padding: 5px 5px 5px 15px;
  border-radius: 8px;
  border: 1px solid #dce2e7;
}

.login-input-error { 
  border: 1px solid #E23E32;
}

.login-error-container {
  display: flex;
  padding: 20px 0;
  align-items: center;
  text-align: justify;
}

.login-error{
  font-size: 12px;
  padding-left: 7px;
  color: #E23E32;
}

.login-field-errors {
  font-size: 12px;
  padding-left: 7px;
  color: #E23E32;
}

.form-container {
  width: 80%;
}

.verifyEmail__container {
  width: 80%;
}

.verifyEmail__notice {
  color: #0047FF;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  margin-bottom: 30px;
}

.verifyEmail__label {
  color: #707684;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  margin-bottom: 30px;
}

.verifyCode__container {
  width: 80%;
}

.verifyCode__label {
  color: #707684;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  margin-bottom: 30px;
}

.Tag {
  margin-top: 15px;
}

.recovery-password-label {
  margin: 24px 0;
}