/* header styles */
.historic__header {
  background-color: #fff;
  box-shadow: 0px 2px 0px #ebedf0;
  display: flex;
  flex-direction: column;
  padding: 20px 20px 0px;
}

.historic__header--title {
  font-style: normal;
  color: #808692;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
}

.historic__header-details {
  display: flex;
}

.historic__header-details--label {
  margin-right: 4px;
}

.historic__details-container {
  display: flex;
  flex-direction: column;
}

.historic__details-item {
  align-items: center;
  display: flex;
  margin-bottom: 16px;
}

.historic__details-item--file {
  margin-bottom: 16px;
  margin-top: 24px;
}

.historic__details--title {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
}

.historic__details--label {
  min-width: 20%;
  font-family: "Manrope";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
}

.historic__details--content {
  min-width: 80%;
}

.historic__header-status {
  padding-top: 20px;
  padding-right: 10px;
}

.historic__header-details--item {
  margin-top: 10px;
  margin-right: 20px;
  display: flex;
  align-items: baseline;
}

.historic__header-details--item .Tag {
  margin-top: 0;
}

.historic__header-containter {
  display: flex;
  justify-content: space-between;
}

.historic__header-actions-container {
  display: flex;
  justify-content: space-between;
}

.historic__header-actions-container .Button {
  margin: 0 10px;
}

/* panel left styles */
.historic__content-container {
  display: flex;
  height: 800px;
}
.historic__container {
  height: 100%;
}

.historic_panel-left-container {
  background-color: #fff;
  border-right: 1px solid #dee1e5;
  box-shadow: 1px 0px 0px rgba(0, 0, 0, 0.1);
  height: 100%;
  overflow: scroll;
  padding: 24px 15px;
  width: 20%;
  min-width: 210px;
}
.historic__step-box {
  border: 1px solid #dee1e5;
  border-radius: 3px;
  display: flex;
  height: 50px;
  margin-bottom: 10px;
  overflow: hidden;
  padding: 5px 5px 5px 15px;
  cursor: pointer;
}

.historic__step-box:hover {
  background: #f5f6f7;
  opacity: 0.8;
}

.step-box__icon-container {
  width: 10%;
  margin-right: 8px;
}

.step-box__labels-container {
  width: 80%;
  display: flex;
  flex-direction: column;
}

.step-box__labels-container-title {
  display: flex;
}

.step-box--title {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: #0c111d;
}

.step-box--index {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: #3c4655;
  margin-right: 8px;
}

.step-box--description {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 20px;
  color: #808692;
}

.isSelected {
  background: #f1f7ff;
  opacity: 0.8;
  border: 1px solid #c7dfff;
  box-sizing: border-box;
  border-radius: 3px;
}

/* content styles */
.historic__content {
  padding: 32px;
}

.historic__content-right-container {
  background: #fafbfb;
  height: 100%;
  overflow: scroll;
  width: 100%;
}

.historic__content__header {
  display: flex;
  margin-bottom: 20px;
}

.historic__content__header svg {
  margin-right: 10px;
}

.historic__content__data {
  background-color: white;
  border: 1px solid #dee1e5;
  border-radius: 4px;
  padding: 20px;
}

.historic__policy__content {
  display: flex;
}

.historic__policy__content--steps {
  box-shadow: 1px 0px 0px rgba(0, 0, 0, 0.1);
  width: 20%;
}

.historic__policy__content--steps-list {
  display: flex;
  flex-direction: column;
  padding: 0 10px 10px;
  margin-top: 10px;
}

.historic__policy__content__item {
  padding-top: 5px;
  cursor: pointer;
}

.historic__policy__content--result {
  padding-left: 20px;
  width: 80%;
}

.historic__nip__message {
  margin-bottom: 16px;
}

.historic__nip__stepBar {
  margin-bottom: 45px;
}
.historic__nip__labels__container {
  display: flex;
}

.historic__nip__labels {
  line-height: 28px;
}
.historic__nip__labels:not(:last-child) {
  margin-right: 32px;
}
.historic__nip__labels label {
  font-weight: 600;
  font-size: 20px;
}
.historic__nip__labels span {
  display: block;
  color: #808692;
  font-size: 12px;
}

.historic__header .Tabs-Container {
  border-bottom: none;
}

.historic__header .badges--icon svg {
  margin-right: 10px;
}
