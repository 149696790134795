#AddEditWorkfloo .Connectors--Panel .Panel_Section_Container {
  height: 100%;
  display: flex;
  flex-direction: column;
}

#AddEditWorkfloo
  .Connectors--Panel
  .Panel_Section_Container
  .ListBox-container {
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 0;
  max-height: none;
}

#AddEditWorkfloo .Connectors--Panel .ListBox-container {
  border: none;
}

#AddEditWorkfloo
  .Connectors--Panel
  .ListBox-container
  .ListBox-container-options {
  padding-left: 30px;
  padding-right: 30px;
}

#AddEditWorkfloo
  .Connectors--Panel
  .ListBox-container
  .ListBox-container-options
  .ItemBox-container {
  flex-wrap: wrap;
}
#AddEditWorkfloo
  .Connectors--Panel
  .ListBox-container
  .ListBox-container-options
  .ItemBox-container
  span {
  display: block;
  width: 100%;
}

#AddEditWorkfloo
  .Connectors--Panel
  .ListBox-container
  .ListBox-container-options
  .ItemBox-container
  svg {
  flex: none;
  display: block;
  margin-bottom: -10px;
}

#AddEditWorkfloo .Connectors-Panels-Container {
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
}

#AddEditWorkfloo .Connectors-Panels-Container .Connectors--Panel {
  flex: none;
  width: 100%;
  max-width: 200px;
}

#AddEditWorkfloo .Connectors-Panels-Container .FlowChart--Panel {
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 0;
  margin-left: 14px;
}

#AddEditWorkfloo
  .Connectors-Panels-Container
  .FlowChart--Panel
  .FlowChart--Graph {
  border: none;
}
