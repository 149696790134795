.Api-AlertContainer {
  margin-top: 10px;
}

.Api-CodeContainer {
  display: flex;
  padding-left: 10px;
  padding-top: 40px;
}

.Api-CodeContainer-Alert {
  padding-top: 10px;
}

.Api-CodeContainer-FileList {
  width: 30%;
  max-width: 280px;
}

.Api-CodeContainer-Code {
  margin-left: 8px;
  width: 100%;
}

.Api-CodeContainer-Code--example{
  margin-bottom: 12px;
  height: 180px;
}

.Api-CodeContainer-Code--example .ExampleSection-panel,
.Api-CodeContainer-Code--example .ExampleSection-panel .Panel_Section_Container {
  height: 100%;
}

.Panel_Main_Container
.Panel_Section_Container {
  border: none;
}

#Api_example {
  height: calc(100% - 48px) !important;
}

.EditorSection-panel {
  flex: 1 1 0;
  height: 350px;
  margin-bottom: 12px;
}

#api_editor_code {
  height: calc(100% - 48px) !important;
}

.OutputSection {
  flex: none;
  height: 214px;
}

#api_output_section {
  height: calc(100% - 48px) !important;
}
