.file-component {
  display: flex;
  align-items: center;
}
.file-component svg {
  margin-right: 16px;
}
.file-component span {
  display: block;
}
.file-component .legend {
  font-weight: 600;
  opacity: 0.7;
  font-size: 12px;
  margin-top: 5px;
}
.radio-buttons-encrypt-file {
  display: flex;
  flex-wrap: wrap;
}
.radio-buttons-encrypt-file label {
  margin-right: 30px;
}

.public-key-container {
  flex: none;
  width: 100%;
  margin-top: 24px;
}
