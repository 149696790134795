.appbar-logout-active {
  position: absolute;
  width: 100%;
  left: 0;
  top: 60px;
  z-index: 1;
}
.grid-main-container {
  height: calc(100vh - 194px);
  max-width: calc(100% - 280px);
  padding: 16px 8px;
}
.grid-main-container > div {
  padding: 0 8px !important;
}

.left-panel,
.grid-main-container > div:nth-child(3) {
  display: flex;
  flex-direction: column;
}

.left-panel,
.grid-main-container > div:nth-child(2),
.left-panel,
.grid-main-container > div:nth-child(3) {
  height: calc(100% - 87px);
}

.left-panel .variable-grid {
  flex: 1 1 0;
}
.left-panel .variable-grid > div {
  padding-left: 8px;
  padding-right: 8px;
  font-family: "Menlo", "Ubuntu Mono", "Consolas", "source-code-pro", monospace !important;
}
.left-panel .variable-grid > div:nth-child(2) {
  max-width: 87px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.left-panel .variables-list > ul {
  list-style: none;
  padding: 0;
  margin: 0;
  max-height: 205px;
  overflow: auto;
}
.left-panel .variables-list > ul > li {
  padding: 12px 0;
  border-top: solid 1px #eaeaea;
}
.left-panel .variables-list .headers {
  background: #f5f5f5;
  border-top: solid 1px #eaeaea;
}

.left-panel .variables-list .headers > div > div:last-child {
  opacity: 0;
}
.left-panel .variables-list > ul > li:not(.headers) > div > div:first-child {
  color: #999;
}

.left-panel .variables-list > ul > li *,
.left-panel .variables-list .headers * {
  font-family: "Manrope", sans-serif !important;
}
.left-panel .variables-list .headers * {
  font-size: 12px;
  font-weight: 600;
}

.left-panel {
  width: 293px;
  height: 100%;
}

.left-panel > div:first-child {
  flex: none;
  height: auto;
}
.left-panel > div:last-child {
  flex: 1 1 0;
  display: flex;
  flex-direction: column;
}

.ace_editor {
  background-color: transparent;
}
.left-panel .ace_editor {
  background-color: transparent;
  height: calc(100% - 30px) !important;
}
.ace_editor .ace_gutter,
.ace_editor .ace_gutter .ace_gutter_cell {
  background-color: transparent;
}

.ace_editor * {
  font-family: "Monaco", "Menlo", "Ubuntu Mono", "Consolas", "source-code-pro",
    monospace !important;
}

.editor-panel {
  flex: 1 1 0;
}

#vp_main_editor {
  height: calc(100% - 48px) !important;
}

.output-panel {
  flex: none;
  margin-top: 16px;
  height: 27%;
}

.output-panel #vp_output_editor {
  height: calc(100% - 25px) !important;
}

.left-panel .ace_gutter-active-line,
#vp_output_editor .ace_gutter-active-line {
  background: none !important;
}

.files-panel {
  background: #fff;
  width: 100%;
  max-width: 280px;
  position: fixed;
  top: 60px;
  right: 0;
  padding: 30px 16px;
  height: calc(100vh - 210px);
  overflow: auto;
  z-index: 0;
}

.files-panel .files-panel-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  padding-left: 10px;
  padding-right: 10px;
}

.files-panel .files-panel-header .files-panel-title {
  font-size: 12px;
  color: #0f0f0f;
  opacity: 0.8;
}

.files-panel .files-container .file-container {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 10px;
  transition: background-color 0.2s ease-in-out;
  -moz-transition: background-color 0.2s ease-in-out;
  -webkit-transition: background-color 0.2s ease-in-out;
  flex: none;
}
.files-panel .files-container .file-container,
.files-panel .files-container .file-container label {
  cursor: pointer;
}
.files-panel .files-container ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
.files-panel .files-container .SortableList--ItemContainer {
  margin: 10px 0 !important;
}
.files-panel .files-container .file-container:hover,
.files-panel .files-container .file-container.active {
  background-color: rgba(199, 223, 255, 0.3);
  border-radius: 4px;
}
.files-panel .files-container .file-container .file-name-container {
  position: relative;
  margin-right: 13px;
  flex: none;
  width: 100%;
  max-width: 107px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.files-panel
  .files-container
  .file-container
  .file-name-container
  .tooltip-indicator {
  position: absolute;
  left: 0;
  top: 100%;
  border: solid 1px #e23e32;
  width: 100%;
  padding: 1px;
  margin-top: -1px;
  color: #ffffff;
  background: #df574d;
  z-index: 100;
}
.files-panel .files-container .file-container .file-name {
  display: block;
  width: 100%;
  border: none;
  background: none;
  padding: 1px;
  outline: none;
}

.files-panel .files-container .file-container .file-name.error {
  border: solid 1px #e23e32;
}

.files-panel .files-container .file-container > svg {
  flex: none;
  width: 18px;
  height: auto;
  stroke: #606367;
  margin-right: 13px;
}
.files-panel .files-container .file-container > div:last-child {
  flex: none;
  width: auto;
}

.head {
  background: #fff;
  margin-bottom: 16px;
  padding: 12px;
}
.head .name-field {
  width: 268px;
}
.head .name-field > div {
  padding: 0;
}
.head .name-field input {
  background: #f0f3f6;
}

.head button > svg {
  fill: #fff;
}

.alert-container {
  padding: 0 16px;
}

.ModalScreen_Variable--Container {
  margin-bottom: 14px;
}
.ModalScreen_Variable--Container > div > div > div > div > div {
  padding-bottom: 0;
}

.ModalScreen_Variable--Container button {
  margin-bottom: 4px;
}

.ModalScreen_Variable--Container > div > div:first-child {
  align-self: center;
  margin-top: 32px;
  font-weight: bold;
  font-size: 14px;
}

.grid-main-container ~ .Modal .Modal-Content {
  max-width: 700px;
}

.Modal-Screen--Variable-Form {
  display: flex;
  align-items: center;
}
.Modal-Screen--Variable-Form .Modal-Screen--Variable-Form--Name,
.Modal-Screen--Variable-Form .Modal-Screen--Variable-Form--Type {
  flex: 1 1 0;
}
.Modal-Screen--Variable-Form .var-form-item:not(:last-child) {
  margin-right: 16px;
}

.Modal-Screen--Variable-Form .Modal-Screen--Variable-Form--Name {
  flex: none;
  width: 344px;
}
