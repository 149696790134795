.filters-container {
  margin-top: 32px;
  width: 100%;
  flex: none;
}
.filters-container .filters-section:not(:first-child) {
  margin-top: 24px;
}
.filters-container .filters-section .filters-section-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.filters-container .filters-section .filters-section-header label {
  opacity: 0.6;
}
.filters-container .filters-section .filters-section-header button {
  border: none;
  padding: 0 8px;
}
.filters-container .filters-section .filters-section-content .filter-field {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
}
.filters-container
  .filters-section
  .filters-section-content
  .filter-field
  > *:not(:last-child) {
  margin-right: 16px;
  flex: none;
  width: calc(33.3333333% - 28px);
}
.filters-container
  .filters-section
  .filters-section-content
  .filter-field
  > .option-field-container {
  padding-bottom: var(--spacing-base-tight);
}
.filters-container
  .filters-section
  .filters-section-content
  .filter-field
  > .option-field-container
  label {
  margin-bottom: var(--spacing-regular);
}
.filters-container
  .filters-section
  .filters-section-content
  .filter-field
  > *:last-child {
  flex: none;
  align-self: center;
  margin-top: 28px;
}
.filters-container
  .filters-section
  .filters-section-content
  .filter-field
  > *:not(.SelectField)
  label {
  margin-bottom: 8px;
}
.filters-container
  .filters-section
  .filters-section-content
  .filter-field
  > .option-field-container
  label {
  margin-bottom: var(--spacing-regular);
  padding-top: var(--spacing-base-tight);
}
.filters-container
  .filters-section
  .filters-section-content
  .filter-field
  > .option-field-container
  .Tag {
  max-width: 100%;
  margin-top: 0;
}
.filters-container
  .filters-section
  .filters-section-content
  .filter-field
  > .option-field-container
  .Tag
  span {
  flex: 1 1 0;
  word-wrap: break-word;
  font-size: 12px;
}
.filters-container
  .filters-section
  .filters-section-content
  .filter-field
  > .option-field-container
  .Tag
  button {
  width: 34px;
  flex: none;
}
.filters-container
  .filters-section
  .filters-section-content
  .filter-field
  input[type="date"] {
  max-height: 42px;
}

/* .filters-container
  .filters-section
  .filters-section-content
  .filter-field
  > .input-date-container {
  padding-bottom: var(--spacing-base-tight);
}
.filters-container
  .filters-section
  .filters-section-content
  .filter-field
  > .input-date-container
  label {
  margin-bottom: var(--spacing-regular);
  line-height: 1;
}
.filters-container
  .filters-section
  .filters-section-content
  .filter-field
  > .input-date-container
  > input {
  height: auto;
  display: inline-flex;
  width: 100%;
  border: solid 1px #dddee1;
  border-radius: var(--radius-regular);
  padding: var(--spacing-base-tight);
  outline: none;
  transition: all 0.2s ease-in-out;
  font-family: inherit !important;
  cursor: pointer;
  font-size: 100%;
  line-height: 1.15;
  margin: 0;
} */

.OptionField--Popover-Container .Popover--Items--Body label {
  flex-wrap: nowrap;
}
.OptionField--Popover-Container .Popover--Items--Body label:before {
  flex: none;
  width: 14px;
  height: 14px;
}
.option-field-container .OptionField {
  flex-wrap: wrap;
}
.option-field-container .OptionField span {
  max-width: 100%;
  justify-content: flex-start;
}
