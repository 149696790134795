
.main-content{
  text-align: left;
  background-color: #F7F7F7;
}

.page-content {
  background-color: #FFF;
}

.logout_button{
background-color: white;
color: gray;
top: 53px;
position: relative;
}
