.rows {
  display: flex;
}

.container-inputs {
  display: flex;
  margin-left: auto;
}

.container-inputs > div:last-child {
  margin-left: 20px;
}