#AddEditCreditPolicies .TablesContainer {
  display: flex;
}
#AddEditCreditPolicies .TablesContainer .Table-Container {
  flex: 1 1 0;
}
#AddEditCreditPolicies .TablesContainer .Table-Container:not(:last-child) {
  margin-right: 15%;
}
#AddEditCreditPolicies
  .TablesContainer
  .Table-Container
  .Table
  .Table-Body
  tr
  td:last-child {
  text-align: right;
}
#AddEditCreditPolicies .node-item {
  display: inline-block;
}
#AddEditCreditPolicies .node-item .Workfloo--Node {
  width: 122px;
  height: auto;
  padding: 6px;
  word-wrap: break-word;
  word-break: break-all;
  border: 3px solid #2850ba;
  border-radius: 4px;
  font-weight: bold;
}
#AddEditCreditPolicies .node-item .Workfloo--Node span {
  word-wrap: break-word;
}
#AddEditCreditPolicies .node-item.step .Workfloo--Node {
  background-color: #2850ba;
}
#AddEditCreditPolicies .node-item.step .Workfloo--Node span {
  color: white;
}
#AddEditCreditPolicies .node-item .Workfloo--Node span svg {
  fill: #fff;
}
#AddEditCreditPolicies .node-item.message .Workfloo--Node {
  background-color: white;
}
#AddEditCreditPolicies .node-item.message .Workfloo--Node span {
  color: #2850ba;
}

#AddEditCreditPolicies .FlowChart--Graph {
  border: none;
}

#AddEditCreditPolicies .Modal:nth-child(4),
#AddEditCreditPolicies .Modal:nth-child(5) {
  padding: 30px;
}
#AddEditCreditPolicies .Modal:nth-child(4) .Modal-Content,
#AddEditCreditPolicies .Modal:nth-child(5) .Modal-Content {
  width: 100%;
  max-width: none;
  height: 100%;
  display: flex;
  flex-direction: column;
}
#AddEditCreditPolicies .Modal:nth-child(4) .Modal-Content .Header-Container,
#AddEditCreditPolicies .Modal:nth-child(5) .Modal-Content .Header-Container {
  flex: none;
  height: auto;
}
#AddEditCreditPolicies .Modal:nth-child(4) .Modal-Content .Body,
#AddEditCreditPolicies .Modal:nth-child(5) .Modal-Content .Body {
  flex: 1 1 0;
  max-height: none;
}
