body {
  margin: 0;
}
* {
  font-family: "Trebuchet MS", Roboto, "Helvetica Neue", sans-serif !important;
}
code {
  font-family: "Inter-Regular";
}

@font-face {
  font-family: "Inter-Light";
  font-weight: 200;
  src: url(./assets/fonts/Inter/Inter-Light-slnt=0.ttf) format("opentype");
}

@font-face {
  font-family: "Inter-Regular";
  font-weight: 400;
  src: url(./assets/fonts/Inter/Inter-Regular-slnt=0.ttf) format("opentype");
}

@font-face {
  font-family: "Inter-Medium";
  font-weight: 500;
  src: url(./assets/fonts/Inter/Inter-Medium-slnt=0.ttf) format("opentype");
}

@font-face {
  font-family: "Inter-Semibold";
  font-weight: 600;
  src: url(./assets/fonts/Inter/Inter-SemiBold-slnt=0.ttf) format("opentype");
}

@font-face {
  font-family: "Inter-Bold";
  font-weight: 700;
  src: url(./assets/fonts/Inter/Inter-Bold-slnt=0.ttf) format("opentype");
}

@font-face {
  font-family: "Inter-Black";
  font-weight: 900;
  src: url(./assets/fonts/Inter/Inter-Black-slnt=0.ttf) format("opentype");
}

.page {
  background-color: #f7f7f7;
  overflow: unset !important;
}
