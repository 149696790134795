#StepModalContent .Display-Text:not(:first-child) {
  margin-top: 32px;
}
#StepModalContent .form-group {
  margin-top: 16px;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
}
#StepModalContent .form-group label {
  display: inline-flex;
  font-size: 0.8571428571428571rem;
  font-weight: 500;
}

#StepModalContent .form-group#StepInfo > * {
  flex: 1 1 0;
}
#StepModalContent .form-group#StepInfo > *:not(:nth-child(2)) {
  margin-left: 16px;
}

#StepModalContent .form-group#StepInfo > *:nth-child(1),
#StepModalContent .form-group#StepInfo > *:last-child {
  flex: none;
  width: 100%;
  margin-left: 0;
}

#StepModalContent .radio-group {
  display: flex;
  margin-top: 8px;
  width: 100%;
  flex: none;
}
#StepModalContent .radio-group > *:not(:last-child) {
  margin-right: 32px;
}

#StepModalContent .form-group#Condition > *:not(:last-child) {
  flex: 1 1 0;
  margin-right: 16px;
}
#StepModalContent .form-group#Condition:not(.has-sum) > *:nth-child(3),
#StepModalContent .form-group#Condition.has-sum > *:nth-child(4) {
  margin-right: 0;
}
#StepModalContent .form-group#Condition > *:last-child {
  flex: none;
  width: 100%;
}
#StepModalContent .form-group#Condition > *:not(.SelectField) label {
  margin-bottom: 8px;
}
