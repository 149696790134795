.container-table-data {
  display: flex;
  flex-direction: column;
  margin: 80px 0;
}

.table-title{
  font-family: Manrope;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  letter-spacing: -0.2px;
  line-height: 32px;
}

.table-data-container {
  display: flex;
  flex-direction: column;
}

.table-row {
  box-shadow: inset 0px -1px 0px #E8E8E8;
  display: flex;
}

.table-row--total {
  box-shadow: inset 0px 1px 0px #E8E8E8, 0px 1px 0px #E8E8E8;
  display: flex;
}

.cell {
  flex: 1;
  font-family: Manrope;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  padding: 14px 0;
}

.cell--header {
  color: #9096A3;
  flex: 1;
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  letter-spacing: 0.01em;
  line-height: 15px;
  padding: 10px 0;
}