#AddEditOpenBanking .Fintoc--Widget--Header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
}

#AddEditOpenBanking .widget--helper {
  opacity: 0.5;
}
