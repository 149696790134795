.input-date {
  flex: 1 1 0;
  border: solid 1px #E8E8E8;
  border-radius: 4px;
  padding: 0.8571428571428571rem;
  outline: none;
}

.input-date:hover {
  border-color: #9096A3;
}

.input-date:focus {
  border-color: #0047FF;
  box-shadow: 0px 0px 0px 3px rgb(0 71 255 / 30%);
}
.logo {
  height: 20px;
  width: 20px;
}
