#AuthNip .Display-Text {
  margin-bottom: var(--spacing-loose);
}

#AuthNip .AnnotatedLayout {
  margin-top: 64px;
}

#AuthNip .AnnotatedLayout .createAccount button {
  margin-top: 36px;
}
#AuthNip .AnnotatedLayout a {
  color: var(--color-primary);
}

.Nip-Container .Secondary-Action-Button {
  cursor: pointer;
}
