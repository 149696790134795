.documents-content {
  display: flex;
  align-items: flex-start;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
}
.side-menu-documents {
  background-color: #fff;
  height: calc(100vh - 154px);
  flex: none;
  width: 305px;
  overflow: auto;
}

.save-button {
  justify-content: center !important;
}

.variable-list {
  display: flex;
  width: 100%;
  padding: 0;
  flex-direction: column;
}

.variable-container {
  display: flex;
  flex-direction: column;
}

.field-list {
  display: flex;
  width: 100%;
  flex-direction: column;
}

.image-content {
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: #f3f3f3;
  padding: 0vh;
  font-size: 0.925vh;
}

.images-content {
  width: 256px;
  display: flex;
  flex-direction: column;
  background-color: #e5e5e5;
  overflow: scroll;
  max-height: calc(100vh - 154px);
}

.image-list {
  width: 100%;
  padding: 20px;
  cursor: pointer;
}
.page-number {
  text-align: center;
}

.pdf-image {
  max-width: 100%;
  display: flex;
  position: relative;
}

.label-content.header {
  padding: 0 12px;
}

.label-content.header .label {
  display: block;
  flex: 1 1 0;
  margin-left: 15px;
}
.label-content.header svg:first-child {
  flex: none;
  width: 18px;
}
.label-content.header svg:last-child {
  transform: rotate(-90deg);
  -webkit-transform: rotate(-90deg);
  -moz-transform: rotate(-90deg);
}

.label-content {
  margin: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}

.label-content.open > svg:last-child {
  opacity: 1;
  transform: rotate(0);
  -webkit-transform: rotate(0);
  -moz-transform: rotate(0);
}

.label-content svg:not(:first-child) {
  flex: none;
  width: 12px;
  height: auto;
  stroke: #6d7580;
  opacity: 0.5;
  margin-left: 10px;
}
.fields-content-container {
  padding: 12px;
  box-sizing: border-box;
  width: 100%;
}
.fields-container {
  box-sizing: border-box;
  border-radius: 4px;
  padding: 4px;
  display: none;
}
.fields-container.open {
  background-color: #f3f3f3;
  display: block;
}
.fields-container .fields-container-body {
  display: flex;
  align-items: stretch;
  flex-wrap: wrap;
}
.label {
  font-size: small;
  display: flex;
  align-items: center;
}

.label-content:not(.header) .label:before {
  flex: none;
  content: "";
  display: block;
  width: 6px;
  height: 6px;
  border: 2px solid #6d7580;
  opacity: 0.6;
  border-radius: 50%;
  margin-left: 13px;
  margin-right: 17px;
  box-sizing: border-box;
}

.fields-container.open .label-content:not(.header) .label:before {
  opacity: 1;
  background-color: #6d7580;
}

.label-content > svg:last-child {
  opacity: 1;
  transform: rotate(-90deg);
  -webkit-transform: rotate(-90deg);
  -moz-transform: rotate(-90deg);
}

.field {
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  border: 1px solid #e6e6e7;
  border-radius: 4px;
  padding: 11px;
  font-size: x-small;
  height: 100%;
  cursor: move;
}

.field-image {
  position: relative;
}

.field-image:hover {
  z-index: 2;
}

.field-image .field-header {
  justify-content: space-between;
  display: none;
  position: absolute;
  bottom: 100%;
  width: 100%;
}
.field-image:hover .field-header,
.field-image.dragging .field-header {
  display: flex;
}

.field-image .action {
  flex: none;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #0047ff;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}

.field-image .action.drag-trigger {
  cursor: move;
}
.field-image .action.delete-button {
  cursor: pointer;
}
.field-image .field-label {
  display: block;
  flex: none;
  width: auto;
  padding: 0.185vh;
  border: solid 0.0925vh transparent;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  line-height: 1;
}

.field-image:hover .field-label,
.field-image.dragging .field-label {
  border-color: #0047ff;
}

.field-image svg g {
  opacity: 1;
}
.field-image svg path {
  fill: #fff !important;
}

.field svg {
  margin-left: 10px;
  flex: none;
  width: 8px;
}

.field-item-container {
  display: inline-block;
  flex: none;
  width: 50%;
  padding: 4px;
}

.fields-container .fields-container-body {
  display: none;
}
.fields-container.open .fields-container-body {
  display: flex;
}

.field-list .fields-list-container {
  display: none;
}
.field-list.open .fields-list-container {
  display: block;
}

.field-list.open .label-content.header svg:last-child {
  transform: rotate(0);
  -webkit-transform: rotate(0);
  -moz-transform: rotate(0);
  opacity: 1;
}
