.InformativeTextField-Container {
  padding: 12px;
  margin: 12px 0;
  border: solid 1px #e8e8e8;
  border-radius: 4px;
}
.InformativeTextField-Container .InformativeTextField {
  -webkit-flex: 1 1 0;
  -ms-flex: 1 1 0;
  flex: 1 1 0;
  border: solid 1px #e8e8e8;
  border-radius: 4px;
  padding: 0.8571428571428571rem;
  outline: none;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  z-index: 1;
}
.InformativeTextField-Container .InformativeTextField:hover {
  border-color: #9096a3;
}
.InformativeTextField-Container .InformativeTextField:focus {
  border-color: #0047ff;
  box-shadow: 0px 0px 0px 3px rgb(0 71 255 / 30%);
}

.InformativeTextField-Container label {
  display: inline-flex;
  font-size: 0.8571428571428571rem;
  font-weight: 500;
  margin-bottom: 16px;
}
.InformativeTextField-Container .preview-container {
  border: solid 1px #e8e8e8;
  border-radius: 4px;
  margin-top: 24px;
  overflow: hidden;
}
.InformativeTextField-Container .preview-container .preview-label-header {
  background: #e8e8e8;
  padding: 12px;
  font-size: 0.8571428571428571rem;
  font-weight: 500;
}
.InformativeTextField-Container .preview-container .preview-content {
  padding: 12px;
}
